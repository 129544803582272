import * as React from 'react';
import { getItemFromLocalStorage, getConfig, useRolePreferences, useFeatureToggles } from '@cpce/core-module';
import { IS_CONVERSATION_HUB_ENABLED } from '@cpce/console-shared-feature-toggles';
import configList from '../../../config';
import { DASHBOARD_URI, LOGIN_URI, CONVERSATION_STUDIO_URI, CONVERSATION_HUB_URI } from '../../constants';
import { isEmpty } from 'lodash';

const HomePage = (props) => {
  const { history, userData } = props;
  const { isLoggedIn, isFederatedSession } = userData;
  const { BOOK_MARK_PATH_KEY, HOME_PAGE_ROUTE_KEY } = getConfig(configList);
  const subAppPath = getItemFromLocalStorage(BOOK_MARK_PATH_KEY);
  const { isLoaded, rolePreferences } = useRolePreferences(React);
  const featureToggles = useFeatureToggles(React);

  React.useEffect(() => {
    let path;

    // This will wait for feature toggle and role preferences to completely loaded before re-routing.
    if (isEmpty(featureToggles) || !isLoaded) return;

    if (!isLoggedIn) {
      path = LOGIN_URI;
    } else if (isFederatedSession) {
      path = featureToggles[IS_CONVERSATION_HUB_ENABLED] ? CONVERSATION_HUB_URI : CONVERSATION_STUDIO_URI;
    } else if (subAppPath) {
      path = subAppPath;
    } else if (isLoaded) {
      path = rolePreferences?.preferences?.[HOME_PAGE_ROUTE_KEY] || DASHBOARD_URI;
    } else {
      path = DASHBOARD_URI;
    }
    history.replace(path);
  }, [isLoggedIn, subAppPath, isLoaded, rolePreferences, featureToggles, HOME_PAGE_ROUTE_KEY, history, isFederatedSession]);

  return null;
};

export default HomePage;
