// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WeT-3I-Cp3-HIUumdRlOSQ\\=\\={bottom:0;left:0;position:fixed;right:0;text-align:center;top:0;z-index:3}.RtqpbswuTCvsuNAzh-BVAg\\=\\={left:50%;position:absolute;top:40%;transform:translate(-50%, -50%)}.U1n23RTXBE8r9A7ERFR-dA\\=\\={background:#fff;opacity:.8}.ku7jeKJPAndL1Cx0z5o-ew\\=\\={color:#000}.MjSorlt0FOS0DPnF1FxIGw\\=\\={background:#212121;opacity:.8}.T5edD3npJWz\\+yB5OAGlF6g\\=\\={color:#fff}.dk5vrjsfwSugE8uhRfCmFA\\=\\={z-index:1300}.GTOoCibWIGqn7BkL20\\+4BA\\=\\={z-index:1100}", ""]);
// Exports
exports.locals = {
	"cover": "WeT-3I-Cp3-HIUumdRlOSQ==",
	"message": "RtqpbswuTCvsuNAzh-BVAg==",
	"light": "U1n23RTXBE8r9A7ERFR-dA==",
	"lightVariantText": "ku7jeKJPAndL1Cx0z5o-ew==",
	"dark": "MjSorlt0FOS0DPnF1FxIGw==",
	"darkVariantText": "T5edD3npJWz+yB5OAGlF6g==",
	"fullScreen": "dk5vrjsfwSugE8uhRfCmFA==",
	"contentOnly": "GTOoCibWIGqn7BkL20+4BA=="
};
module.exports = exports;
