export default {
  IAM_APP_CLIENT_ID: '3skatiou5npb8c12eflh0toe7f',
  IAM_OKTA_APP_CLIENT_ID: '0oa1hqajfdlL0qpOj4x7',
  IAM_DOMAIN_URL: 'https://int.vap.expedia.com',
  SHOW_NOTIFICATION_BEFORE_TOKEN_EXPIRES_IN_MINS: 58,
  AUTO_REFRESH_TOKEN: true,
  // TODO: https://jira.expedia.biz/browse/CPCE-99597
  EG_LOGIN_URL: {
    baseUrl: 'https://accounts.test-expediagroup.com',
    queryStrings: {
      client_id: 'fda40480-fa48-4f09-8f7d-e040b5769217',
      idp: 'bf959423e106e1db986e88d6ed8d95c396731b9439d3cdc7814db83d5202f7cc',
    },
  },
};
