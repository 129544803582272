export default {
  MAX_ATTACHMENTS_COUNT: 10,
  ERROR_REPORT_ENDPOINT: '/api/v1/errorreport',
  APP_DATA_ENDPOINT: '/api/v1/appData?isEnabled=true',
  IAM_APP_CLIENT_ID: '4b1bfc0tthlm3ba0if5u7o1dga',
  IAM_OKTA_APP_CLIENT_ID: '0oa1hq9f1aUpcmSHS4x7',
  EG_LOGIN_URL: {
    urlPath: '/api/v2/oidc/authorize',
    redirectUrlPath: '/login',
    queryStrings: {
      scope: 'openid',
      response_type: 'code',
      state: 'some-state12',
    },
  },
  IAM_DOMAIN_URL: 'https://lab.vap.expedia.com',
  IAM_V5_LOGIN_PATH: '/auth/v5/login?appClientId=',
  BACK_END_EG_LOGIN_ENDPOINT: '/api/v1/iam/eg/login',
  BACK_END_EG_LOGOUT_ENDPOINT: '/api/v1/iam/eg/logout',
  BACK_END_REFRESH_EG_PRINCIPAL_TOKEN: '/api/v1/iam/eg/refresh',
  BACK_END_FT_ENDPOINT: '/api/v1/feature-toggles',
  BACK_END_FT_BY_NAME_ENDPOINT: '/api/v1/feature-toggles/{toggleName}',
  BACK_END_LOGIN_ENDPOINT: '/api/v1/iam/login',
  BACK_END_LOGOUT_ENDPOINT: '/api/v1/iam/logout/{appClientId}',
  BACK_END_TOKEN_REFRESH_ENDPOINT: '/api/v1/iam/refresh',
  BACK_END_FETCH_PARTNER_DETAILS_ENDPOINT: '/api/v1/iam/get-user-permissions-for-trusted-partner',
  BACK_END_EG_IDENTITY_FETCH_PARTNER_DETAILS_ENDPOINT: '/api/v1/iam/eg/get-user-permissions-for-trusted-partner',
  CONSOLE_LOGIN_PATH: '/login',
  SHOW_NOTIFICATION_BEFORE_TOKEN_EXPIRES_IN_MINS: 1,
  SHOW_NOTIFICATION_BEFORE_FEDERATED_SESSION_TOKEN_EXPIRES_IN_MINS: 1,
  AUTO_REFRESH_TOKEN: false,
  FEATURE_TOGGLES_REFRESH_INTERVAL_MS: 600000,
  BOOK_MARK_PATH_KEY: 'bookMarkPath',
  HOME_PAGE_ROUTE_KEY: 'homepageRoute',
  BACK_END_EG_IDENTITY_AUTH_FLOW_ENDPOINTS: {
    EXCHANGE_AUTH_CODE_ENDPOINT: '/api/v1/iam/eg/token',
    GET_USER_DATA_ENDPOINT: '/api/v1/iam/eg/userdata',
  },
  OPEN_WORLD_HOME_URL: 'https://consoleexpediagroupcom.staging.exp-test.net/home',
  FEDERATED_SESSION_AUTH_CODE: 'federatedSessionAuthCode',
  FEDERATED_SESSION_START_TIMESTAMP: 'federatedSessionStartTimestamp',
  FEDERATED_SESSION_ROLE_PREFERENCES: {
    homepageRoute: '/virtual-agents',
  },
  DATADOG_RUM_APPLICATION_ID: '8c20a178-c8c8-4ab2-a10c-478cf8021896',
  DATADOG_RUM_CLIENT_TOKEN: 'pub8f4b2dd8f1da5a632c9792017523a989',
  DATADOG_RUM_SITE: 'datadoghq.com',
  DATADOG_RUM_SERVICE: 'cpce-console-extension-framework',
  DOCS_URL: 'https://developers.expediagroup.com/docs/virtual-assistant',
  OPEN_WORLD_EXPEDIA_LOGO: 'https://a.travel-assets.com/travel-assets-manager/eg-platform-console/eg-e-logo-dark.svg',
  EG_IDENTITY_DOMAIN_URL:
    'https://identity-authorization-server.rcp.identity.test-cts.exp-aws.net/api/v3/oauth2/authorize?idp=0oa3iaj7tengKWHSL1d7&scope=openid',
  EG_IDENTITY_CLIENT_ID: '0oa69x57jtWtpnfRz1d7',
  EG_IDENTITY_CODE_CHALLENGE_STEP_1: 'x6t-02ngMTvR1DjJPK8MsfdXpHEj-cDKUyU5QnEG3FA',
  EG_IDENTITY_CODE_CHALLENGE_STEP_2: 'cR6gzuBTtZhwNku4QitDT7GZvskprHXg72XjTYN4_OE',
  EG_IDENTITY_STATE_STEP_1: 'eglogin1',
  EG_IDENTITY_STATE_STEP_2: 'eglogin2',
  SUB_APPS_WITHOUT_NAV_BAR: ['@cpce/eg-affiliates-managed', '@cpce/omp-console'],
};
