import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ferris/react/components/icon';
import { DropDown } from '@ferris/react/components/drop-downs';
import { Button } from '@ferris/react/components/button';
import { ButtonType } from '@ferris/react/components/button/Button';
import icons from '@ferris/core/icons/icon-manifest-standard.json';
import styles from './global-navbar-dropdown.module.scss';

const ICONS = [...icons.expedia, ...icons.material];

const GlobalNavbarDropdown = (props) => {
  const { buttonText, buttonType, iconName, buttonProps, classes, children, open, triggerText, onRequestToggle, ...otherProps } = props;

  const handleRequestToggle = (e, { open }) => {
    onRequestToggle?.(open);
  };

  return (
    <div className={styles['global_navbar_dropdown']}>
      <DropDown
        classes={classes}
        open={open}
        triggerButton={
          <Button type={buttonType} {...buttonProps}>
            {buttonText}
            {iconName && <Icon name={iconName} title={iconName} />}
          </Button>
        }
        textTrigger={triggerText}
        onRequestToggle={handleRequestToggle}
        {...otherProps}
      >
        {children}
      </DropDown>
    </div>
  );
};

GlobalNavbarDropdown.propTypes = {
  buttonText: PropTypes.string,
  buttonType: PropTypes.oneOf(Object.values(ButtonType)),
  iconName: PropTypes.oneOf(ICONS),
  buttonProps: PropTypes.object,
  open: PropTypes.bool,
  classes: PropTypes.string,
  children: PropTypes.node,
  triggerText: PropTypes.bool,
  onRequestToggle: PropTypes.func,
  otherProps: PropTypes.object,
};

GlobalNavbarDropdown.defaultProps = {
  open: false,
  triggerText: true,
  buttonType: ButtonType.UTILITY,
};

export { GlobalNavbarDropdown };
