import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { DropDown } from '@ferris/react/components/drop-downs';
import { Radio } from '@ferris/react/components/forms';
import { chunk, times } from 'lodash';
import PropTypes from 'prop-types';
import { Button } from '@ferris/react/components/button';
import { Icon } from '@ferris/react/components/icon';
import { SearchBox } from '@vapc-ui/search-box';

import classes from './PartnerSwitch.module.scss';

const PARTNER_COLUMNS = 4;

const PartnerSwitch = (props) => {
  const { open: pOpen, options, onChange, selectedPartner } = props;
  const [filter, setFilter] = useState('');
  const [partnerId, setPartnerId] = useState(selectedPartner.id);
  const [open, setOpen] = useState(pOpen);
  const [width, setWidth] = useState('auto');

  const pinWidthToUnfiltered_cb = useCallback(pinWidthToUnfiltered, [open, options]);

  const optionGroups = useMemo(() => {
    const sortedOptions = options.slice().sort((a, b) => a.partnerName.localeCompare(b.partnerName));
    const filteredOptions = sortedOptions.filter((so) => RegExp(filter, 'i').test(so.partnerName));
    return chunk(filteredOptions, Math.ceil(options.length / PARTNER_COLUMNS));
  }, [filter, options]);

  const escapeRegExp = (originalString) => {
    return originalString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const handleSearchTermChange = (searchValue) => {
    if (searchValue) {
      const searchRegExp = new RegExp(escapeRegExp(searchValue), 'i');
      setFilter(searchRegExp);
    } else {
      setFilter('');
    }
  };

  const handleSearchBoxClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    setPartnerId(selectedPartner.id);
  }, [selectedPartner.id]);

  return options.length > 1 ? (
    <>
      <span className={classes['partner-name']}>Partner name:</span>
      <DropDown
        alignment="right"
        cancelText="Cancel"
        classes={classes['partner-switch']}
        hasForm
        onCancel={handleCancel}
        onRequestToggle={handleRequestToggle}
        onUpdate={handleUpdate}
        open={open}
        updateText="Switch"
        triggerButton={
          <Button>
            {`${selectedPartner.name || 'Switch Partners'}`}
            <Icon name={'arrow-drop-down'} />
          </Button>
        }
        textTrigger
      >
        <div className={classes['search-box-container']} role="button" tabIndex="0" onClick={handleSearchBoxClick}>
          {open && <SearchBox label="Search partner" onChange={handleSearchTermChange} initialValue="" />}
        </div>
        <div className={classes['radio-list']} ref={pinWidthToUnfiltered_cb} style={{ width }}>
          {times(PARTNER_COLUMNS, (idx) => (
            <div key={idx}>
              {optionGroups[idx]?.map(({ id, partnerName }, index) => (
                <Radio checked={partnerId === id} key={`${id}-${index}`} label={partnerName} onChange={handleChange} value={id} />
              ))}
            </div>
          ))}
        </div>
      </DropDown>
    </>
  ) : null;

  function handleCancel() {
    if (partnerId !== selectedPartner.id) {
      setPartnerId(selectedPartner.id);
    }
    setFilter('');
    setOpen(false);
  }

  function handleChange(event) {
    setPartnerId(event.currentTarget.value);
  }

  function handleRequestToggle() {
    setFilter('');
    setOpen(!open);
  }

  function handleUpdate(event) {
    onChange?.(event, partnerId);
    setFilter('');
    setOpen(false);
  }

  function pinWidthToUnfiltered(dropdownRef) {
    if (open) {
      setWidth(dropdownRef?.offsetWidth);
    }
  }
};

PartnerSwitch.propTypes = {
  open: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      partnerName: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onChange: PropTypes.func,
  selectedPartner: PropTypes.object,
};

PartnerSwitch.defaultProps = {
  selectedPartner: {},
};

export default PartnerSwitch;
