import * as React from 'react';
import { Button } from '@ferris/react/components/button';
import { useToken, useFeatureToggles } from '@cpce/core-module';
import styles from './CopyToken.module.scss';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CopyToken = ({ className }) => {
  const token = useToken(React);

  const handleClick = () => {
    copy(token.idToken);
  };

  return (
    <Button classes={classNames(styles.copyToken, className)} type="link" onClick={handleClick}>
      Copy token
    </Button>
  );
};

CopyToken.prototype = {
  className: PropTypes.string,
};

export default CopyToken;
