import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { logout, getConfig, sendLog, getAppClientId, egLogout } from '@cpce/core-module';
import configList from '../../../config';
import { useHistory } from 'react-router-dom';
import { LoadingScreen } from '@isp/shared-components/src/components/loading-screen/LoadingScreen';

const DEFAULT_REDIRECT = '/';

const Logout = ({ isFederatedSession, isLoggingOutFederatedSession, toggleIsLoggingOutFederatedSession, isEGLoginSession }) => {
  const history = useHistory();
  const configData = getConfig(configList);
  const { OPEN_WORLD_HOME_URL } = configData;

  const cleanupAndRedirect = () => {
    history.push(DEFAULT_REDIRECT);
  };

  useEffect(() => {
    if (isFederatedSession) {
      toggleIsLoggingOutFederatedSession(true);
      sendLog('Logout from federated session is initiated', true);
      egLogout(configData)
        .then(() => {
          window.open(OPEN_WORLD_HOME_URL, '_self');
        })
        .catch((e) => {
          sendLog(`Failed to clear federated session due to ${e}`, true);
        });
    } else if (isEGLoginSession) {
      sendLog('Logout from Eg identity login session is initiated', true);
      egLogout(configData)
        .then(() => {
          cleanupAndRedirect();
        })
        .catch((e) => {
          sendLog(`Failed to clear Eg identity session due to ${e}`, true);
        });
    } else {
      sendLog(`Logout is invoked - appClientId: ${getAppClientId(configData.IAM_APP_CLIENT_ID)}`, true);
      logout(configData)
        .then(() => {
          cleanupAndRedirect();
        })
        .catch(() => {
          cleanupAndRedirect();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isFederatedSession || isLoggingOutFederatedSession ? <LoadingScreen visible message="Redirecting to open world..." /> : null;
};

Logout.prototype = {
  isFederatedSession: PropTypes.bool,
  isLoggingOutFederatedSession: PropTypes.bool,
  toggleIsLoggingOutFederatedSession: PropTypes.func.isRequired,
};

export default Logout;
