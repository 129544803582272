import * as React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@ferris/react/components/cards';
import { Button } from '@ferris/react/components/button';
import { Icon } from '@ferris/react/components/icon';
import { FontIcon } from '@vapc-ui/font-icon';
import copy from 'copy-to-clipboard';
import CopyToken from '../copy-token/CopyToken';

import styles from './UserProfile.module.scss';

const UserProfile = ({ userName = '', userFullName = '', partnerName = '', userRole = '', partnerId = '' }) => {
  const initials = userName.charAt(0).toUpperCase();

  const handleCopy = () => {
    copy(JSON.stringify({ name: partnerName, id: partnerId }));
  };

  return (
    <Card className={styles.userProfile}>
      <CardContent>
        <div className={styles.avatarWrapper}>
          <div className={styles.avatar}>{initials}</div>
        </div>
        <div className={styles.userInfo}>
          {userName || userFullName ? (
            <div className={styles.userName}>{userFullName ? `${userFullName} (${userName})` : userName}</div>
          ) : null}
          {userRole ? (
            <div className={styles.partnerRole}>
              <Icon before inline inlineIconSize="fds-icon-inline-300" name="assignment-ind" />
              {userRole}
            </div>
          ) : null}
          {partnerName ? (
            <div className={styles.partnerName}>
              <Icon before inline inlineIconSize="fds-icon-inline-300" name="business-center" />
              {partnerName}
              <Button classes={styles.copyPartnerId} variant="link" title="Click to copy partner information" onClick={handleCopy}>
                <FontIcon icon="clone" />
              </Button>
            </div>
          ) : null}
          <CopyToken />
          <NavLink to="/logout" className={styles.logout}>
            <Icon before inline inlineIconSize="fds-icon-inline-300" name="logout" />
            Log out
          </NavLink>
        </div>
      </CardContent>
    </Card>
  );
};

UserProfile.prototype = {
  userName: PropTypes.string,
  userFullName: PropTypes.string,
  partnerName: PropTypes.string,
  userRole: PropTypes.string,
  partnerId: PropTypes.string,
};

export default UserProfile;
