import React from 'react';
import PropTypes from 'prop-types';
import { UitkPrimaryButton as Button, UitkSecondaryButton as BackButton } from 'uitk-react-button';
import { Modal } from '@vapc-ui/modal';
import { usePartnerSwitchConfirmation } from '@cpce/core-module';
import './PartnerSwitchModal.scss';

const PartnerSwitchModal = (props) => {
  const { cancel, confirm, config } = props;
  const partnerSwitchConfirmationData = usePartnerSwitchConfirmation(React);

  return (
    <Modal {...partnerSwitchConfirmationData} title={'Save Changes'} onCloseModal={cancel} className="partnerSwitchModal">
      Any unsaved changes you've made will be lost. To save changes, go back and save your work.
      <hr />
      <div className="buttonContainer">
        <BackButton id="partnerSwitchCancel" variant="cancel" onClick={cancel}>
          Go back
        </BackButton>
        <Button id="partnerSwitchConfirm" variant="secondary" onClick={() => confirm(partnerSwitchConfirmationData.partner, config)}>
          Switch partner
        </Button>
      </div>
    </Modal>
  );
};

PartnerSwitchModal.propTypes = {
  cancel: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  config: PropTypes.object,
};

export default PartnerSwitchModal;
