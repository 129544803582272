// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".fds-button.d7Bs8Qwa0\\+Aky2fX0gwlJw\\=\\={margin-bottom:1.6px}", ""]);
// Exports
exports.locals = {
	"rootErrorModalButton": "d7Bs8Qwa0+Aky2fX0gwlJw=="
};
module.exports = exports;
