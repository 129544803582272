import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@vapc-ui/modal';
import { Button } from '@ferris/react/components/button';
import { DASHBOARD_URI } from '../../constants';

import styles from './ErrorModal.module.scss';

const RETURN_TO_OW_HOME_PAGE_TEXT = 'return to the Open World home page from';
const RETURN_TO_CONSOLE_HOME_PAGE_TEXT = 'return to the home page';
const HERE = 'here';
const CONVERSATION_STUDIO = 'Conversation Studio';

const RedirectButton = ({ onClick, buttonText = HERE }) => (
  <Button type="link" onClick={onClick} classes={styles.rootErrorModalButton}>
    {buttonText}
  </Button>
);

const Content = ({ isUnauthorizedErr, onClick, isFederatedSession, hasEgIdentityAppAccessIssue }) => {
  if (isUnauthorizedErr) {
    return (
      <div>
        You are not authorized to access {isFederatedSession ? CONVERSATION_STUDIO : 'this page'}. Please{' '}
        {isFederatedSession ? RETURN_TO_OW_HOME_PAGE_TEXT : ' '}
        <RedirectButton onClick={onClick} buttonText={isFederatedSession ? HERE : RETURN_TO_CONSOLE_HOME_PAGE_TEXT} />
      </div>
    );
  } else if (hasEgIdentityAppAccessIssue) {
    return (
      <div>
        This app is not supported in EG Identity flow. Please{' '}
        <RedirectButton onClick={onClick} buttonText={RETURN_TO_CONSOLE_HOME_PAGE_TEXT} />
      </div>
    );
  } else {
    return (
      <div>
        We could not direct you to the {CONVERSATION_STUDIO} successfully because partnerId is not found. You can{' '}
        {RETURN_TO_OW_HOME_PAGE_TEXT}
        <RedirectButton onClick={onClick} />
      </div>
    );
  }
};

const ErrorModal = ({ show, isFederatedSession, isUnauthorizedErr, isLoggedIn, hasEgIdentityAppAccessIssue }) => {
  const handleOpenWorldOnClick = () => {
    if (isFederatedSession) {
      window.history.replaceState('', 'logout', '/logout');
    } else {
      if (isLoggedIn) {
        window.location.href = DASHBOARD_URI;
      } else {
        window.history.replaceState('', 'logout', '/logout');
      }
    }
  };

  return (
    <Modal show={show} variant="warning" title="Error" size="lg" hideCloseButton>
      <Content
        isFederatedSession={isFederatedSession}
        onClick={handleOpenWorldOnClick}
        isUnauthorizedErr={isUnauthorizedErr}
        hasEgIdentityAppAccessIssue={hasEgIdentityAppAccessIssue}
      />
    </Modal>
  );
};

ErrorModal.propTypes = {
  show: PropTypes.bool,
  isFederatedSession: PropTypes.bool,
  isUnauthorizedErr: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  hasEgIdentityAppAccessIssue: PropTypes.bool,
};

export { ErrorModal };
