import * as React from 'react';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFeatureToggles } from '@cpce/core-module';
import { FerrisPage } from '@ferris/react/components/page-templates';
import { Card, CardContent } from '@ferris/react/components/cards';
import { List, ListItem, ListItemContent, PrimaryContent, ContentHeader, ContentDescription } from '@ferris/react/components/list';
import { LinkButton } from '@ferris/react/components/button/type';
import { getTotalColumns, STYLES_DATA } from './helpers';
import styles from './DropdownContent.module.scss';

const MENU_WIDTH_STYLES = [
  styles['menu_with_one_column_width'],
  styles['menu_with_two_columns_width'],
  styles['menu_with_three_columns_width'],
];

const DropdownContent = ({ cards, onClick }) => {
  const history = useHistory();
  const featureToggles = useFeatureToggles(React);
  const totalColumns = React.useMemo(() => getTotalColumns(cards, STYLES_DATA.MAX_HEIGHT), [cards]);

  const handleClick = (route) => {
    onClick();
    history.push(route);
  };

  const ListItemRowLink = ({ header, route }) =>
    featureToggles.isOpenNewTabWindowEnabled ? (
      <Link to={route} className={styles['menu-link']} onClick={onClick}>
        {header}
      </Link>
    ) : (
      <LinkButton variant="link-standard" onClick={() => handleClick(route)}>
        {header}
      </LinkButton>
    );

  const ListItemRow = ({ header, headerClassName, description, route, toRender }) => {
    return toRender ? (
      <ListItem key={`${header}-${route}`}>
        <PrimaryContent>
          <ListItemContent>
            {header ? (
              <ContentHeader classes={headerClassName}>{route ? <ListItemRowLink route={route} header={header} /> : header}</ContentHeader>
            ) : null}
            {description ? <ContentDescription>{description}</ContentDescription> : null}
          </ListItemContent>
        </PrimaryContent>
      </ListItem>
    ) : null;
  };

  return Array.isArray(cards) && totalColumns !== 0 ? (
    <FerrisPage classes={classNames(styles.menu, MENU_WIDTH_STYLES[totalColumns - 1])}>
      <div className={styles['menu_columns']}>
        {cards.map(({ cardTitle, cardOverview, cardImage, categorizedLinks, uncategorizedLinks }) => {
          return (
            <Card key={`${cardTitle}-card`}>
              <CardContent>
                <List noninteractive>
                  <ListItem className={styles['menu_image_and_title']}>
                    <img src={cardImage} alt={cardTitle} width={STYLES_DATA.IMAGE_HEIGHT} height={STYLES_DATA.IMAGE_HEIGHT} />
                    <span>{cardTitle}</span>
                  </ListItem>
                  <ListItemRow header="Overview" description={cardOverview} toRender={!!cardOverview} />
                  {uncategorizedLinks.map(({ route, text, description }) => {
                    return (
                      <ListItemRow key={route} header={text} description={description} route={route} toRender={!!text || !!description} />
                    );
                  })}
                  {categorizedLinks.map(({ categoryTitle, links }) => {
                    return (
                      <div key={categoryTitle}>
                        <ListItemRow header={categoryTitle} headerClassName={styles.category} toRender={!!categoryTitle} />
                        {links?.map(({ route, text, description }) => {
                          return (
                            <ListItemRow
                              key={route}
                              header={text}
                              description={description}
                              route={route}
                              toRender={!!text || !!description}
                            />
                          );
                        })}
                      </div>
                    );
                  })}
                </List>
              </CardContent>
            </Card>
          );
        })}
      </div>
    </FerrisPage>
  ) : null;
};

DropdownContent.prototype = {
  cards: PropTypes.array,
  onClick: PropTypes.func,
};

export default DropdownContent;
