// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".iIGKSx9fI\\+D\\+crpMbEi9wA\\=\\={z-index:1027;position:fixed;min-width:30%;max-width:min-content;top:-100%;transform:translateY(-130%);transition-timing-function:ease-in;transition:.2s;left:35%;right:auto}.iIGKSx9fI\\+D\\+crpMbEi9wA\\=\\= .fds-banner{margin:0}.iIGKSx9fI\\+D\\+crpMbEi9wA\\=\\=._3zBcAwAFtmAk-rTxut\\+8Jw\\=\\={transition:.25s;transition-timing-function:ease-out;transform:translateY(0);top:.1rem}.iIGKSx9fI\\+D\\+crpMbEi9wA\\=\\= .d40CDFPZ1AHRfsTjem2A0Q\\=\\={margin:0 auto}.iIGKSx9fI\\+D\\+crpMbEi9wA\\=\\= .zlRFMrJlOZKaS8Xqom3UgQ\\=\\={position:absolute;top:.5rem;right:.5rem;font-size:1rem}.RU22p1hZJjFlm7EIo2bcig\\=\\={margin-top:10px;display:inline-block;border-top:rgba(255,255,255,.8) 1px solid;width:calc(100% - 20px);padding-top:8px;padding-left:8px;font-style:italic;position:relative;text-align:left}.RU22p1hZJjFlm7EIo2bcig\\=\\=>ul{list-style:disc}.RU22p1hZJjFlm7EIo2bcig\\=\\=>ul>li{list-style-position:inside}._6lR-lUXYIC3w8ZeB8kJDpg\\=\\=>div:first-child{width:calc(100% - 60px)}", ""]);
// Exports
exports.locals = {
	"alertNotification": "iIGKSx9fI+D+crpMbEi9wA==",
	"open": "_3zBcAwAFtmAk-rTxut+8Jw==",
	"alert": "d40CDFPZ1AHRfsTjem2A0Q==",
	"alertCloseIcon": "zlRFMrJlOZKaS8Xqom3UgQ==",
	"alertActualMessage": "RU22p1hZJjFlm7EIo2bcig==",
	"alertContent": "_6lR-lUXYIC3w8ZeB8kJDpg=="
};
module.exports = exports;
