import * as React from 'react';
import { NavLink, Link } from 'react-router-dom';
import expediaLogo2 from '../../../assets/images/compressed/eps_logo-02-2.png';
import {
  getNavigationItems,
  partnerSwitch,
  setPartnerSwitchConfirmation,
  resetSelectedPartner,
  useFeatureToggles,
} from '@cpce/core-module';
import PartnerSwitch from '../../partner-switch/PartnerSwitch';
import PartnerSwitchModal from '../../partner-switch/PartnerSwitchModal';
import { globalNavbarItemsByCategory } from './../helpers/mergeNavItemsByDropdown';
import DropdownContent from '../../dropdown-content/DropdownContent';
import UserProfile from '../../user-profile/UserProfile';
import { DASHBOARD_URI } from '../../../constants';
import CpassNavbar from '../cpaas-navbar/CpaasNavbar';
import { GlobalNavbarDropdown } from '../../global-navbar-dropdown-component/global-navbar-dropdown';
import { GlobalNavigationSiteHeader } from 'uitk-react-global-navigation';
import { UitkLayoutFlexItem, UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkTertiaryButton } from 'uitk-react-button';
import './GlobalNavBar.scss';

const GlobalNavBar = ({ userData, trustedPartners, configData, history, ...props }) => {
  const {
    isLoggedIn,
    userName,
    activePartner: { profile: { partnerName, firstName, lastName, partnerId, role: userRole } = {} } = {},
    isFederatedSession,
    hasCPaaSManagementPermission,
  } = userData || {};
  const navigationItems = globalNavbarItemsByCategory(getNavigationItems());
  const userFullName = `${firstName ? firstName + ' ' : ''}${lastName || ''}`;
  const featureToggles = useFeatureToggles(React);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState({});
  const homeUrl = DASHBOARD_URI;
  const isCpaasSession = !!(isFederatedSession && hasCPaaSManagementPermission);

  const createLinks = () => {
    const defaultLinks = [{ url: homeUrl, name: 'Dashboard' }];

    return defaultLinks;
  };
  const linksArray = createLinks();

  const changePartner = (event, partnerId) => {
    setPartnerSwitchConfirmation(partnerId);
  };

  const handleCloseModal = () => {
    setPartnerSwitchConfirmation();
  };

  const confirmPartnerSwitch = async (partnerId, configData) => {
    setPartnerSwitchConfirmation();
    history.push(homeUrl);
    await partnerSwitch(partnerId, configData);
  };

  const handleNavbarDropdownOnToggle = (menutitle) => (open) => {
    resetSelectedPartner();
    setIsDropdownOpen({
      ...isDropdownOpen,
      [menutitle]: open,
    });
  };

  const handleDropdownMenuOnClick = (menuTitle) => () => {
    resetSelectedPartner();
    setIsDropdownOpen({});
  };

  return isLoggedIn && isCpaasSession ? (
    <CpassNavbar userData={userData} />
  ) : isLoggedIn ? (
    <>
      <UitkLayoutFlexItem className="cpaas-navbar">
        <GlobalNavigationSiteHeader>
          <UitkSpacing
            padding={{
              inline: 'four',
              block: 'one',
            }}
          >
            <UitkLayoutFlex alignItems="center" justifyContent="start" space="two">
              <UitkLayoutFlexItem>
                <Link to={homeUrl}>
                  <img src={expediaLogo2} alt="Expedia Logo" width="200" height="50" className={'nav_expedia_logo'} />
                </Link>
              </UitkLayoutFlexItem>

              <UitkLayoutFlexItem minWidth="full_width">
                <UitkLayoutFlex justifyContent="space-between">
                  <UitkLayoutFlex justifyContent="start" space="four">
                    <UitkLayoutFlexItem>
                      <UitkSpacing
                        margin={{
                          inlinestart: 'twelve',
                        }}
                      >
                        <UitkTertiaryButton>
                          {linksArray.map(({ url, name }) => {
                            return (
                              <NavLink key={name} to={{ pathname: url }} className="cpass-nav-link override-link-style">
                                {name}
                              </NavLink>
                            );
                          })}
                        </UitkTertiaryButton>
                      </UitkSpacing>
                    </UitkLayoutFlexItem>

                    <UitkLayoutFlexItem>
                      <UitkSpacing
                        margin={{
                          inline: 'twelve',
                        }}
                      >
                        <UitkTertiaryButton>
                          {navigationItems.map(({ menuTitle, cards }) => (
                            <GlobalNavbarDropdown
                              open={isDropdownOpen[menuTitle]}
                              onRequestToggle={handleNavbarDropdownOnToggle(menuTitle)}
                              buttonText={menuTitle}
                              iconName="expand-more"
                              key={menuTitle}
                            >
                              <DropdownContent cards={cards} onClick={handleDropdownMenuOnClick(menuTitle)} />
                            </GlobalNavbarDropdown>
                          ))}
                        </UitkTertiaryButton>
                      </UitkSpacing>
                    </UitkLayoutFlexItem>
                  </UitkLayoutFlex>

                  <UitkLayoutFlex alignItems="center">
                    <UitkLayoutFlexItem>
                      <div>
                        <UitkLayoutFlex justifyContent="space-between">
                          <PartnerSwitchModal cancel={handleCloseModal} confirm={confirmPartnerSwitch} config={configData} />
                          <UitkLayoutFlexItem>
                            <div>
                              <PartnerSwitch
                                onChange={changePartner}
                                options={trustedPartners}
                                selectedPartner={{ name: partnerName, id: partnerId }}
                              />
                            </div>
                          </UitkLayoutFlexItem>
                          <UitkLayoutFlexItem alignSelf="center">
                            <div>
                              <GlobalNavbarDropdown iconName="account-circle" classes="profile">
                                <UserProfile
                                  userName={userName}
                                  userFullName={userFullName}
                                  partnerName={partnerName}
                                  userRole={userRole}
                                  partnerId={partnerId}
                                  isFederatedSession={isFederatedSession}
                                  isCpaasSession={isCpaasSession}
                                />
                              </GlobalNavbarDropdown>
                            </div>
                          </UitkLayoutFlexItem>
                        </UitkLayoutFlex>
                      </div>
                    </UitkLayoutFlexItem>
                  </UitkLayoutFlex>
                </UitkLayoutFlex>
              </UitkLayoutFlexItem>
            </UitkLayoutFlex>
          </UitkSpacing>
        </GlobalNavigationSiteHeader>
      </UitkLayoutFlexItem>
    </>
  ) : null;
};

export default GlobalNavBar;
