import { datadogRum } from '@datadog/browser-rum';
import * as React from 'react';
import { getConfig, getFlavor, useUser, useFeatureToggles } from '@cpce/core-module';
import configList from '../../../config';

const ablyUrlsToBeExcluded = ['ably.io', 'ably-realtime.com'];
const oneTrustUrlsToBeExcluded = ['cdn.cookielaw.org', 'onetrust.com'];
const excludedUrls = [ablyUrlsToBeExcluded, oneTrustUrlsToBeExcluded].flat();

export const DataDogRUM = function () {
  const configData = getConfig(configList);
  const { DATADOG_RUM_APPLICATION_ID } = configData;
  const { DATADOG_RUM_CLIENT_TOKEN } = configData;
  const { DATADOG_RUM_SITE } = configData;
  const { DATADOG_RUM_SERVICE } = configData;
  const flavor = getFlavor();
  const userData = useUser(React);
  const featureToggles = useFeatureToggles(React);

  React.useEffect(() => {
    datadogRum.init({
      // below details are of Expedia Internal
      applicationId: DATADOG_RUM_APPLICATION_ID,
      clientToken: DATADOG_RUM_CLIENT_TOKEN,
      site: DATADOG_RUM_SITE,
      service: DATADOG_RUM_SERVICE,
      version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      env: flavor,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [(url) => excludedUrls.every((excluded) => !url.includes(excluded))],
      excludedActivityUrls: [(url) => excludedUrls.some((excluded) => url.includes(excluded))],
    });

    datadogRum.startSessionReplayRecording();
  }, []);

  React.useEffect(() => {
    datadogRum.setUser({
      id: userData?.userId,
      email: userData?.profile?.emailId,
      name: `${userData?.profile?.firstName} ${userData?.profile?.lastName}`,
      roleName: userData?.profile?.role,
      roleId: userData?.role,
      activePartnerName: userData?.activePartner?.profile?.partnerName,
      activePartnerId: userData?.activePartner?.profile?.partnerId,
      activePartnerType: userData?.activePartner?.profile?.partnerType,
      profilePartnerName: userData?.profile?.partnerName,
      profilePartnerId: userData?.profile?.partnerId,
      profilePartnerType: userData?.profile?.partnerType,
    });
  }, [featureToggles, userData]);

  return null;
};
