import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Root from './Root';

const RootWrapper = () => {
  return (
    <Router>
      <Root />
    </Router>
  );
};

export default RootWrapper;
