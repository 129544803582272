// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1VUQzRSi9nsvSnPkzYV6WQ\\=\\={max-height:700px}.vgrY5naB1n65TESA3LH8LQ\\=\\={background-color:#cccceb}.FLyqhZPJ6n4MvauP8Z-5rQ\\=\\={padding-right:20px}.-ZAXb-JRV8smwS2T2h5TMg\\=\\={top:50%;left:50%;position:absolute;max-width:960px;transform:translate(-50%, -60%)}.xn\\+b0BP51BcnJfYGPrMyDA\\=\\={margin-top:100px;display:block;color:rgba(0,0,0,.87);font-size:2.125rem;font-family:\"Roboto\",\"Helvetica\",\"Arial\",sans-serif;font-weight:400;line-height:1.17;letter-spacing:.00735em;margin-bottom:.35em}.lSdXsIlMArbt0zVk49gECA\\=\\={margin-top:50px;display:block;color:rgba(0,0,0,.87);font-size:1rem;font-family:\"Roboto\",\"Helvetica\",\"Arial\",sans-serif;font-weight:400;line-height:1.75;letter-spacing:.00938em;margin-bottom:20px}.LeZTmgE2KCxKMJg6oAAcEA\\=\\={margin-top:100px}.H5JBlu5Jz5ARipGCfEepXA\\=\\={display:block !important;font-family:\"CenturyGothicBold\" !important}", ""]);
// Exports
exports.locals = {
	"imgLoginSplash": "_1VUQzRSi9nsvSnPkzYV6WQ==",
	"loginSplash": "vgrY5naB1n65TESA3LH8LQ==",
	"loginEmailInput": "FLyqhZPJ6n4MvauP8Z-5rQ==",
	"loginContainer": "-ZAXb-JRV8smwS2T2h5TMg==",
	"loginHeader": "xn+b0BP51BcnJfYGPrMyDA==",
	"loginContent": "lSdXsIlMArbt0zVk49gECA==",
	"loginMargin": "LeZTmgE2KCxKMJg6oAAcEA==",
	"linkCSS": "H5JBlu5Jz5ARipGCfEepXA=="
};
module.exports = exports;
