// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!@vapc-ui/styles/dist/styles.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!@vapc-ui/button/dist/button.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!@vapc-ui/checkbox/dist/checkbox.css");
var ___CSS_LOADER_AT_RULE_IMPORT_3___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!@vapc-ui/dashboard-card/dist/dashboard-card.css");
var ___CSS_LOADER_AT_RULE_IMPORT_4___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!@vapc-ui/new-dashboard-card/dist/dashboard-card.css");
var ___CSS_LOADER_AT_RULE_IMPORT_5___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!@vapc-ui/data-table/dist/data-table.css");
var ___CSS_LOADER_AT_RULE_IMPORT_6___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!@vapc-ui/filter-menu/dist/filter-menu.css");
var ___CSS_LOADER_AT_RULE_IMPORT_7___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!@vapc-ui/font-icon/dist/font-icon.css");
var ___CSS_LOADER_AT_RULE_IMPORT_8___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!@vapc-ui/form-control/dist/form-control.css");
var ___CSS_LOADER_AT_RULE_IMPORT_9___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!@vapc-ui/form-group/dist/form-group.css");
var ___CSS_LOADER_AT_RULE_IMPORT_10___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!@vapc-ui/grid/dist/grid.css");
var ___CSS_LOADER_AT_RULE_IMPORT_11___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!@vapc-ui/modal/dist/modal.css");
var ___CSS_LOADER_AT_RULE_IMPORT_12___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!@vapc-ui/toolbar/dist/toolbar.css");
var ___CSS_LOADER_AT_RULE_IMPORT_13___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!@ferris/react/css/ferris-default-ltr.min.css");
var ___CSS_LOADER_AT_RULE_IMPORT_14___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!@vapc-ui/search-box/dist/search-box.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_8___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_9___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_10___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_11___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_12___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_13___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_14___);
// Module
exports.push([module.id, "div[id=\"single-spa-application:root-app\"]:not(:empty)~div[id^=\"single-spa-application:\"]:not(:empty){margin-top:50px;height:calc(100% - 50px)}div[id=\"single-spa-application:root-app\"]:only-of-type,div[id=\"single-spa-application:root-app\"]~div[id^=\"single-spa-application:\"]:not(:empty){height:100%}", ""]);
// Exports
module.exports = exports;
