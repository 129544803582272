export const STYLES_DATA = Object.freeze({
  CONTENT_HEADER_HEIGHT: 20,
  CONTENT_DESCRIPTION_HEIGHT: 18,
  IMAGE_HEIGHT: 56,
  LIST_ITEM_PRIMARY_HEIGHT: 66,
  MAX_HEIGHT: 780,
  CONTENT_DESCRIPTION_MARGIN_TOP: 4,
  CONTENT_HEADER_TEXT_MAX_LENGTH: 47,
  CONTENT_DESCRIPTION_TEXT_MAX_LENGTH: 60,
  CATEGORY_TITLE_MAX_LENGTH: 45,
  PADDING: 12,
});
