// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".eW\\+lsvuM39y62XyQO3O4iw\\=\\={line-height:18px;color:#FFFFFF;font-size:.875rem}.KpkI7H9Z8EunsgrrP-OqDg\\=\\= .fds-dropdown-button{background-color:#ffc60b;border-radius:0;border:none;margin-right:20px !important}.KpkI7H9Z8EunsgrrP-OqDg\\=\\= .fds-dropdown-content{min-width:754px !important;margin-top:20px !important}.KpkI7H9Z8EunsgrrP-OqDg\\=\\= .fds-dropdown-content .fds-dropdown-inner-content{max-height:406px !important;color:#000}.KpkI7H9Z8EunsgrrP-OqDg\\=\\= .fds-button{margin:0;color:#fff !important;min-width:auto}.KpkI7H9Z8EunsgrrP-OqDg\\=\\= .fds-button .fds-button-label{font-weight:400 !important;white-space:nowrap}.KpkI7H9Z8EunsgrrP-OqDg\\=\\= .fds-button-action .fds-button-label{text-transform:capitalize !important}.KpkI7H9Z8EunsgrrP-OqDg\\=\\= .fds-field-input{margin-bottom:1em !important;width:290px !important}.KpkI7H9Z8EunsgrrP-OqDg\\=\\= .fds-switch-radio{overflow:hidden !important;white-space:nowrap !important}.KpkI7H9Z8EunsgrrP-OqDg\\=\\= .fds-switch-control,.KpkI7H9Z8EunsgrrP-OqDg\\=\\= .fds-switch-control::before{border-width:2px !important}.KpkI7H9Z8EunsgrrP-OqDg\\=\\= .fds-switch-input:checked + .fds-switch-control{border-width:5px !important}.KpkI7H9Z8EunsgrrP-OqDg\\=\\= .tWDj\\+0uFstyIOGsvQqmjrw\\=\\={display:flex !important}.KpkI7H9Z8EunsgrrP-OqDg\\=\\= .tWDj\\+0uFstyIOGsvQqmjrw\\=\\= div:not(:first-child){margin-left:32px !important}.KpkI7H9Z8EunsgrrP-OqDg\\=\\= .rJl9YZobgwZJpAx10UrUKA\\=\\={width:295px}", ""]);
// Exports
exports.locals = {
	"partner-name": "eW+lsvuM39y62XyQO3O4iw==",
	"partner-switch": "KpkI7H9Z8EunsgrrP-OqDg==",
	"radio-list": "tWDj+0uFstyIOGsvQqmjrw==",
	"search-box-container": "rJl9YZobgwZJpAx10UrUKA=="
};
module.exports = exports;
