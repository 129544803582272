export default {
  IAM_DOMAIN_URL: 'https://demo.vap.expedia.com',
  IAM_APP_CLIENT_ID: '54prkjbjf6ga3b75iska00d6mt',
  IAM_OKTA_APP_CLIENT_ID: '0oa1ib8qfptKhcTSV4x7',
  // TODO: https://jira.expedia.biz/browse/CPCE-99597
  EG_LOGIN_URL: {
    baseUrl: 'http://TBD',
    queryStrings: {
      client_id: 'TBD',
      idp: 'TBD',
    },
  },
};
