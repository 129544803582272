import * as React from 'react';
import { GlobalNavigationSiteHeader } from 'uitk-react-global-navigation';
import { UitkAvatar } from 'uitk-react-avatar';
import { UitkTertiaryButton } from 'uitk-react-button';
import { UitkHeaderBrandLogo } from 'uitk-react-header-brand-logo';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkIcon } from 'uitk-react-icons';
import { UitkMenu, UitkMenuTrigger, UitkMenuContainer } from 'uitk-react-menu';
import { UitkHeading, UitkSubheading, UitkParagraph } from 'uitk-react-text';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { getConfig } from '@cpce/core-module';
import configList from '../../../../config';
import PropTypes from 'prop-types';
import { CONVERSATION_STUDIO_URI, CPAAS_REPORTS_URI } from '../../../constants';

import './CpaasNavbar.scss';

const handleOpenWorldOnClick = () => {
  window.history.replaceState('', 'logout', '/logout');
};

const CpassNavbar = ({ userData }) => {
  const { userName, activePartner: { profile: { firstName, lastName, role } = {} } = {} } = userData || {};
  const configs = getConfig(configList);
  const { OPEN_WORLD_HOME_URL, DOCS_URL, OPEN_WORLD_EXPEDIA_LOGO } = configs;

  const [showUserDetails, setShowUserDetails] = React.useState(false);

  const userInitials = `${firstName?.slice(0, 1) || ''}${lastName?.slice(0, 1) || ''}`;
  const userFullName = `${firstName ? firstName + ' ' : ''}${lastName || ''}`;

  return (
    <GlobalNavigationSiteHeader className="cpaas-navbar">
      <UitkSpacing
        padding={{
          inline: 'six',
          block: 'three',
        }}
      >
        <UitkLayoutFlex alignItems="center" justifyContent="start" space="six">
          <UitkLayoutFlexItem>
            <UitkHeaderBrandLogo altText="expedia logo" href={OPEN_WORLD_HOME_URL} imgSrc={OPEN_WORLD_EXPEDIA_LOGO} />
          </UitkLayoutFlexItem>
          <UitkLayoutFlexItem minWidth="full_width">
            <UitkLayoutFlex justifyContent="end" space="four">
              <UitkLayoutFlexItem>
                <UitkSpacing
                  padding={{
                    inline: 'two',
                  }}
                >
                  <UitkTertiaryButton href={CONVERSATION_STUDIO_URI} tag="a">
                    <UitkIcon name="home" size="medium" />
                    Virtual Assistant designer
                  </UitkTertiaryButton>
                </UitkSpacing>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem>
                <UitkSpacing
                  padding={{
                    inline: 'two',
                  }}
                >
                  <UitkTertiaryButton href={CPAAS_REPORTS_URI} tag="a">
                    <UitkIcon name="data_trends" size="medium" />
                    Analytics
                  </UitkTertiaryButton>
                </UitkSpacing>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem>
                <UitkSpacing
                  padding={{
                    inline: 'two',
                  }}
                >
                  <UitkTertiaryButton href={DOCS_URL} tag="a">
                    <UitkIcon name="help" size="medium" />
                    View Documentation
                  </UitkTertiaryButton>
                </UitkSpacing>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem>
                <UitkSpacing
                  padding={{
                    inline: 'two',
                  }}
                >
                  <UitkMenu isOpen={showUserDetails}>
                    <UitkMenuTrigger>
                      <UitkTertiaryButton onClick={() => setShowUserDetails((prev) => !prev)}>
                        <UitkAvatar border={false} size="medium" text={userInitials} />
                      </UitkTertiaryButton>
                    </UitkMenuTrigger>
                    <UitkMenuContainer autoPosition width={350}>
                      <UitkSpacing
                        padding={{
                          inline: 'six',
                        }}
                      >
                        <UitkLayoutGrid columns={['18x', '1fr']}>
                          <UitkLayoutGridItem>
                            <UitkSpacing
                              margin={{
                                blockstart: 'six',
                              }}
                            >
                              <UitkAvatar border={false} size="large" text={userInitials} />
                            </UitkSpacing>
                          </UitkLayoutGridItem>
                          <UitkLayoutGridItem>
                            <UitkLayoutGrid justifyItems="start">
                              <UitkSpacing
                                padding={{
                                  inline: 'two',
                                }}
                              >
                                <UitkHeading tag="h2" size={6}>
                                  {userFullName}
                                </UitkHeading>
                              </UitkSpacing>
                              <UitkSpacing
                                padding={{
                                  inline: 'two',
                                }}
                              >
                                <UitkSubheading tag="h5">{userName}</UitkSubheading>
                              </UitkSpacing>
                              <UitkTertiaryButton onClick={handleOpenWorldOnClick}>
                                <UitkParagraph>Open world</UitkParagraph>
                              </UitkTertiaryButton>
                            </UitkLayoutGrid>
                          </UitkLayoutGridItem>
                        </UitkLayoutGrid>
                      </UitkSpacing>
                    </UitkMenuContainer>
                  </UitkMenu>
                </UitkSpacing>
              </UitkLayoutFlexItem>
            </UitkLayoutFlex>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
      </UitkSpacing>
    </GlobalNavigationSiteHeader>
  );
};

CpassNavbar.propTypes = {
  userData: PropTypes.shape({
    userName: PropTypes.string,
    activePartner: PropTypes.shape({
      profile: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        role: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default CpassNavbar;
