import './set-public-path';

import SubAppWrapper from '@cpce/console-sub-app';

import RootWrapper from './RootWrapper';

const subAppWrapper = new SubAppWrapper();
const lifecycles = subAppWrapper.wrap(RootWrapper);

export const { bootstrap, mount, unmount } = lifecycles;
