import all from './all';
import demo from './demo';
import dev from './dev';
import int from './int';
import lab from './lab';
import prod from './prod';

const configList = {
  all,
  demo,
  dev,
  int,
  lab,
  prod,
};

export default configList;
