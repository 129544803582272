// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3qcwlNNY53Z6Zs7tUT7AAg\\=\\={color:#000;width:347px}._3qcwlNNY53Z6Zs7tUT7AAg\\=\\= .fds-card-content{display:inline-flex;flex-wrap:nowrap}._3qcwlNNY53Z6Zs7tUT7AAg\\=\\= .fds-card-content .Wlokfg\\+Lh6IT-sTHGEG55Q\\=\\={width:25%}._3qcwlNNY53Z6Zs7tUT7AAg\\=\\= .fds-card-content .Wlokfg\\+Lh6IT-sTHGEG55Q\\=\\= ._0aRoC9LSGe\\+LfHwft-tVRw\\=\\={width:56px;height:56px;display:flex;align-items:center;justify-content:center;background-color:#9d9dfb;border-radius:50%;color:#fff;font-weight:bold;font-size:22px;margin-right:0}._3qcwlNNY53Z6Zs7tUT7AAg\\=\\= .fds-card-content .qGTY4YMJuRiW\\+ioDxsNIqA\\=\\={width:75%;display:flex;flex-direction:column;align-items:flex-start}._3qcwlNNY53Z6Zs7tUT7AAg\\=\\= .fds-card-content .qGTY4YMJuRiW\\+ioDxsNIqA\\=\\= .m8rsLb3FeKkSsh2Wy8hNZQ\\=\\={font-weight:bold;padding-bottom:12px;margin-right:0}._3qcwlNNY53Z6Zs7tUT7AAg\\=\\= .fds-card-content .qGTY4YMJuRiW\\+ioDxsNIqA\\=\\= .lekBpW-3lUMgLplq0wky-Q\\=\\=,._3qcwlNNY53Z6Zs7tUT7AAg\\=\\= .fds-card-content .qGTY4YMJuRiW\\+ioDxsNIqA\\=\\= .jId5XlVIvmEcOFH00UrrdA\\=\\={padding-bottom:12px;margin-right:0}._3qcwlNNY53Z6Zs7tUT7AAg\\=\\= .fds-card-content .qGTY4YMJuRiW\\+ioDxsNIqA\\=\\= .ti7uEW1rrsaquTsefeS4wg\\=\\={color:#0047ff !important}._3qcwlNNY53Z6Zs7tUT7AAg\\=\\= .fds-card-content .qGTY4YMJuRiW\\+ioDxsNIqA\\=\\= .c4I2C4TgIfS-mAOZ6b1PEQ\\=\\={background-color:transparent;box-shadow:none;color:#00b1ff;min-height:14px;min-width:16px;margin:0 0 0 10px}._3qcwlNNY53Z6Zs7tUT7AAg\\=\\= .fds-card-content .qGTY4YMJuRiW\\+ioDxsNIqA\\=\\= .c4I2C4TgIfS-mAOZ6b1PEQ\\=\\= .fds-button-label{padding:0}._3qcwlNNY53Z6Zs7tUT7AAg\\=\\= .fds-card-content .qGTY4YMJuRiW\\+ioDxsNIqA\\=\\= ._49aSIB0RIKxwD5u1K7OXhw\\=\\={min-height:14px;min-width:16px;margin:0 0 0 10px}._3qcwlNNY53Z6Zs7tUT7AAg\\=\\= .fds-card-content .qGTY4YMJuRiW\\+ioDxsNIqA\\=\\= ._49aSIB0RIKxwD5u1K7OXhw\\=\\= .fds-button-label{padding:0}", ""]);
// Exports
exports.locals = {
	"userProfile": "_3qcwlNNY53Z6Zs7tUT7AAg==",
	"avatarWrapper": "Wlokfg+Lh6IT-sTHGEG55Q==",
	"avatar": "_0aRoC9LSGe+LfHwft-tVRw==",
	"userInfo": "qGTY4YMJuRiW+ioDxsNIqA==",
	"userName": "m8rsLb3FeKkSsh2Wy8hNZQ==",
	"partnerName": "lekBpW-3lUMgLplq0wky-Q==",
	"partnerRole": "jId5XlVIvmEcOFH00UrrdA==",
	"logout": "ti7uEW1rrsaquTsefeS4wg==",
	"copyPartnerId": "c4I2C4TgIfS-mAOZ6b1PEQ==",
	"copyToken": "_49aSIB0RIKxwD5u1K7OXhw=="
};
module.exports = exports;
