export default {
  IAM_DOMAIN_URL: 'https://vap.expedia.com',
  IAM_APP_CLIENT_ID: '4nk6d9929a591kl3rr8hn34l5n',
  IAM_OKTA_APP_CLIENT_ID: '0oa6qmzok63x52ILC357',
  // TODO: https://jira.expedia.biz/browse/CPCE-99597
  EG_LOGIN_URL: {
    baseUrl: 'http://TBD',
    queryStrings: {
      client_id: 'TBD',
      idp: 'TBD',
    },
  },
  CPCE_STATUS_PAGE_URL: 'https://cp.statuspage.io/',
  OPEN_WORLD_HOME_URL: 'https://console.expediagroup.com/home',
  DATADOG_RUM_APPLICATION_ID: '30557385-0d36-44a1-a301-7a73a4fdcdcd',
  DATADOG_RUM_CLIENT_TOKEN: 'pub86b051a2a24d124713875ca788dd76ef',
  EG_IDENTITY_DOMAIN_URL: 'https://accounts.expediagroup.com/api/v3/oauth2/authorize?idp=0oaz5zb1nf7pbd6BU696&scope=openid',
  EG_IDENTITY_CLIENT_ID: '0oa5b844q18AGqYAO697',
};
