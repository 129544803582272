import contactCenterImage from '../../../assets/images/EG_Icons_OfficeWorkplace_Headphones.png';
import developerToolsImage from '../../../assets/images/EG_Icons_TechPrograms_Coding.png';
import omnichannelImage from '../../../assets/images/EG_Icons_CommTransform_Workflow_1.png';
import companyDetailsImage from '../../../assets/images/EG_Icons_OfficeWorkplace_Campus.png';
import virtualAgentImage from '../../../assets/images/EG_Icons_CommTransform_Communication.png';
import partnersImage from '../../../assets/images/EG_Icons_CommTransform_Connecting-Networking.png';
import analyticsImage from '../../../assets/images/EG_Icons_OfficeWorkplace_Wellness.png';
import { GLOBAL_NAV_CATEGORY_TYPES, GLOBAL_NAV_TOP_LEVEL_ORDERED_MENU } from '@cpce/core-module';
import { uniqBy } from 'lodash';

export const mergeNavItemsByDropdown = (navItems) => {
  if (Array.isArray(navItems)) {
    return navItems.reduce((list, item) => {
      const { title, alwaysShowAsDropdown, links } = item;
      const existingDropdownIndex = list.findIndex(
        (newItem) => newItem.title === title && newItem.alwaysShowAsDropdown === alwaysShowAsDropdown
      );
      const isMergeNeeded = existingDropdownIndex !== -1 && alwaysShowAsDropdown;

      if (isMergeNeeded) {
        list[existingDropdownIndex].links.push(...links);
      } else {
        list.push({ ...item, links: [...links] });
      }

      return list;
    }, []);
  }

  return [];
};

const CARD_INFO = [
  {
    name: 'Virtual agent',
    cardImage: virtualAgentImage,
    // cardOverview: 'See how your virtual agent is set up', //uncomment this menu item later once we know its functionality
  },
  {
    name: 'Contact center',
    cardImage: contactCenterImage,
    cardOverview: 'How your contact center is set up',
  },
  {
    name: 'All channels',
    cardImage: omnichannelImage,
  },
  {
    name: "Your company's details",
    cardImage: companyDetailsImage,
  },
  {
    name: 'Partners',
    cardImage: partnersImage,
  },
  {
    name: 'Developer tools',
    cardImage: developerToolsImage,
  },
  {
    name: 'Omnichannel',
    cardImage: omnichannelImage,
  },
  {
    name: 'Analytics',
    cardImage: analyticsImage,
  },
];

const compareByKey = (key) => (item1, item2) => (item1[key] < item2[key] ? -1 : item1[key] > item2[key] ? 1 : 0);

const getMergedNavbarItemsByParentCategory = (navItems) => {
  if (Array.isArray(navItems)) {
    const categoriesIds = GLOBAL_NAV_CATEGORY_TYPES.map(({ id }) => id);
    const navItemsByParentCategory = navItems.reduce((mergedList, item) => {
      const { parentCategoryId, links } = item;
      if (parentCategoryId && categoriesIds.includes(parentCategoryId)) {
        const { topLevelMenu, innerLevelMenu } = GLOBAL_NAV_CATEGORY_TYPES.find((category) => category.id === parentCategoryId);
        const existingMenuIndex = mergedList.findIndex((menu) => menu.menuTitle === topLevelMenu);

        if (existingMenuIndex !== -1) {
          const existingCardIndex = mergedList[existingMenuIndex].cards.findIndex((card) => card.cardTitle === innerLevelMenu);
          if (existingCardIndex !== -1) {
            mergedList[existingMenuIndex].cards[existingCardIndex].links.push(...links);
          } else {
            mergedList[existingMenuIndex].cards.push({ cardTitle: innerLevelMenu, links });
          }
        } else {
          mergedList.push({
            menuTitle: topLevelMenu,
            cards: [{ cardTitle: innerLevelMenu, links }],
            order: GLOBAL_NAV_TOP_LEVEL_ORDERED_MENU.indexOf(topLevelMenu),
          });
        }
      }
      return mergedList;
    }, []);

    return navItemsByParentCategory.sort(compareByKey('order')).map((item) => ({
      ...item,
      cards: item.cards
        .sort(compareByKey('cardTitle'))
        .map((cardItem) => ({ ...cardItem, links: cardItem.links.sort(compareByKey('text')) })),
    }));
  }

  return [];
};

export const globalNavbarItemsByCategory = (navItems) => {
  const mergedData = getMergedNavbarItemsByParentCategory(navItems);

  let resultedMergedData = [];
  mergedData.forEach((dropdownMenu) => {
    const { cards, menuTitle } = dropdownMenu;
    let resultedCardsArray = [];
    cards.forEach((card) => {
      const { cardTitle, links } = card;
      const { cardImage, cardOverview } = CARD_INFO.find((card) => card.name === cardTitle) || {};

      let categorizedLinks = [];
      let uncategorizedLinks = [];

      uniqBy(links, 'route').forEach((link) => {
        const { route, text, description, category: currCategory } = link;
        const newLink = {
          route,
          text,
          description,
        };

        if (currCategory) {
          const existingCategoryIndex = categorizedLinks.findIndex(({ categoryTitle }) => categoryTitle === currCategory);
          if (existingCategoryIndex === -1) {
            categorizedLinks.push({ categoryTitle: currCategory, links: [newLink] });
          } else {
            categorizedLinks[existingCategoryIndex].links.push(newLink);
          }
        } else {
          uncategorizedLinks.push(newLink);
        }
      });
      resultedCardsArray.push({
        ...card,
        cardImage,
        cardOverview,
        categorizedLinks: categorizedLinks.sort(compareByKey('categoryTitle')),
        uncategorizedLinks,
      });
    });
    resultedMergedData.push({ menuTitle, cards: resultedCardsArray });
  });

  return resultedMergedData;
};
