// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "button.ea5X-XADb4YQ6RmLh5caYA\\=\\={margin-bottom:12px}", ""]);
// Exports
exports.locals = {
	"copyToken": "ea5X-XADb4YQ6RmLh5caYA=="
};
module.exports = exports;
