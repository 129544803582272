import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { UitkLoader } from 'uitk-react-loader';
import classes from './LoadingScreen.module.scss';

const defaultVariant = classes.light;
const variantTypes = [defaultVariant, classes.dark];
const defaultCoverage = classes.fullScreen;
const coverageTypes = [defaultCoverage, classes.contentOnly];

export const LoadingScreen = (props) => {
  const { message, visible, coverage = defaultCoverage, variant = defaultVariant } = props;
  const className = props.className || '';
  const messageToDisplay = !!message && !!message.length ? message : 'Loading';
  const variantClass = variantTypes.includes(variant) ? variant : defaultVariant;
  const variantTextClass = `${variantClass}VariantText`;
  const coverageClass = coverageTypes.includes(coverage) ? coverage : defaultCoverage;

  return visible ? (
    <div className={classNames(classes.cover, variantClass, coverageClass, className)} data-testid="loading-screen">
      <span className={classNames(classes.message, variantTextClass)}>
        <UitkLoader title={messageToDisplay} />
      </span>
    </div>
  ) : null;
};

LoadingScreen.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  variant: PropTypes.oneOf(variantTypes),
  visible: PropTypes.bool.isRequired,
  coverage: PropTypes.oneOf(coverageTypes),
};
