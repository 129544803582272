import * as React from 'react';

import { useEgPartnerList, getConfig, sendLog } from '@cpce/core-module';
import configList from '../../../config';

import { UitkView } from 'uitk-react-view';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkRadioButton, UitkRadioButtonGroup } from 'uitk-react-radio-button';
import { UitkTextInput } from 'uitk-react-input';
import { UitkPrimaryButton } from 'uitk-react-button';
import { UitkScrollable } from 'uitk-react-scrollable';
import { UitkBanner } from 'uitk-react-banner';
import { UitkIcon } from 'uitk-react-icons';
import { UitkHeading } from 'uitk-react-text';

const EgPartnerSelection = () => {
  const configData = getConfig(configList);
  const { EG_IDENTITY_DOMAIN_URL, EG_IDENTITY_CLIENT_ID, EG_IDENTITY_CODE_CHALLENGE_STEP_2, EG_IDENTITY_STATE_STEP_2 } = configData;

  const EG_IDENTITY_LOGIN_URL = `${EG_IDENTITY_DOMAIN_URL}&client_id=${EG_IDENTITY_CLIENT_ID}&code_challenge=${EG_IDENTITY_CODE_CHALLENGE_STEP_2}&state=${EG_IDENTITY_STATE_STEP_2}&redirect_uri=${window.location.origin}/dashboard`;

  const egPartnerList = useEgPartnerList(React);

  const [partner, setPartner] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [message, setMessage] = React.useState('');

  const filteredPartnerList = React.useMemo(() => {
    return egPartnerList?.filter((partner) => partner.partnerName.toLowerCase().includes(search.toLowerCase())) || [];
  }, [egPartnerList, search]);

  React.useEffect(() => {
    if (egPartnerList && egPartnerList?.length > 0) {
      setMessage('');
      if (egPartnerList.length === 1) {
        sendLog(`URL: ${EG_IDENTITY_LOGIN_URL}&assumed_partner_account_id=${egPartnerList[0].egPartnerId}`, true);
        window.location = `${EG_IDENTITY_LOGIN_URL}&assumed_partner_account_id=${egPartnerList[0].egPartnerId}`;
      }
    } else {
      setMessage('No User Roles or Partners Found');
    }
  }, [egPartnerList]);

  React.useEffect(() => {
    if (egPartnerList?.length && !filteredPartnerList.length) setMessage('No results found!');
  }, [filteredPartnerList]);

  const redirectWithPartner = async () => {
    sendLog(`URL: ${EG_IDENTITY_LOGIN_URL}&assumed_partner_account_id=${partner}`, true);
    window.location = `${EG_IDENTITY_LOGIN_URL}&assumed_partner_account_id=${partner}`;
  };

  const onSearchTextChange = (e) => {
    setSearch(e.target.value);
    setMessage('');
  };

  return (
    <UitkView>
      <UitkSpacing padding="twelve">
        <UitkLayoutGrid blockSize="calc(100vh)" rows="1fr 3fr 1fr">
          <UitkLayoutGridItem>
            <UitkLayoutFlex justifyContent="center" alignItems="center" direction="column">
              <UitkLayoutFlexItem>
                <UitkSpacing margin="six">
                  <div>
                    <UitkTextInput id="Search Partner" label="Search Partner" value={search} onChange={onSearchTextChange} />
                  </div>
                </UitkSpacing>
              </UitkLayoutFlexItem>
            </UitkLayoutFlex>
          </UitkLayoutGridItem>
          <UitkLayoutGridItem>
            <UitkScrollable direction="vertical">
              <UitkLayoutFlex justifyContent="center" alignItems="center" direction="column">
                <UitkLayoutFlexItem>
                  {message ? (
                    <UitkBanner>
                      <UitkLayoutFlex alignItems="center" space="two">
                        <UitkLayoutFlexItem>
                          <div>
                            <UitkIcon name="error" spotlight={{ theme: 'standard', background: false }} />
                          </div>
                        </UitkLayoutFlexItem>
                        <UitkLayoutFlexItem>
                          <div>
                            <UitkHeading size={7} tag="h2">
                              {message}
                            </UitkHeading>
                          </div>
                        </UitkLayoutFlexItem>
                      </UitkLayoutFlex>
                    </UitkBanner>
                  ) : (
                    <UitkRadioButtonGroup
                      onChange={(e) => {
                        setPartner(e.currentTarget.value);
                      }}
                      selected={partner}
                    >
                      {filteredPartnerList.map((partner) => (
                        <UitkRadioButton id={partner.egPartnerId} value={partner.egPartnerId}>
                          {partner.partnerName}
                        </UitkRadioButton>
                      ))}
                    </UitkRadioButtonGroup>
                  )}
                </UitkLayoutFlexItem>
              </UitkLayoutFlex>
            </UitkScrollable>
          </UitkLayoutGridItem>
          <UitkLayoutGridItem>
            <UitkLayoutFlex justifyContent="center" alignItems="center" direction="column">
              <UitkLayoutFlex>
                <UitkSpacing margin="six">
                  <UitkPrimaryButton onClick={redirectWithPartner} disabled={!partner}>
                    Submit
                  </UitkPrimaryButton>
                </UitkSpacing>
              </UitkLayoutFlex>
            </UitkLayoutFlex>
          </UitkLayoutGridItem>
        </UitkLayoutGrid>
      </UitkSpacing>
    </UitkView>
  );
};

export default EgPartnerSelection;
