import * as React from 'react';
import { Prompt } from 'react-router-dom';

const PROMPT_MESSAGE = 'Are you sure you want to leave?';

const NavigationPrompt = () => {
  const [navigationPromptEnabled, setNavigationPromptEnabled] = React.useState(false);

  window.setNavigationPromptEnable = React.useCallback((enabled) => {
    setNavigationPromptEnabled(enabled);
  }, []);

  return <Prompt when={navigationPromptEnabled} message={PROMPT_MESSAGE} />;
};

export default NavigationPrompt;

// make sure to disable prompt when the component unmounts
