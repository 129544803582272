import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Banner, BannerHeader, BannerNote } from '@ferris/react/components/banner';
import classNames from 'classnames';
import classes from './Snackbar.module.scss';

const bannerTypes = ['attention', 'error', 'info', 'success', 'tip'];
const defaultAutoHideDuration = 6000;
const defaultBannerType = 'info';

const Snackbar = (props) => {
  const {
    autoHideDuration = defaultAutoHideDuration,
    onClose,
    message,
    bannerNote,
    bannerType = defaultBannerType,
    actualErrorMessages,
    open = false,
  } = props;

  const timerIdRef = useRef(0);
  const isBannerMessageStacked = actualErrorMessages && !isEmpty(actualErrorMessages);
  const [visible, setVisibility] = useState(open);
  const wrapperRef = useRef();

  const startTimer = () => {
    timerIdRef.current = setTimeout(() => handleClose(), autoHideDuration);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      handleClose();
    }
  };

  const handleMouseOver = (event) => {
    if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
      clearTimeout(timerIdRef.current);
    }
  };

  const handleMouseOut = (event) => {
    if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
      startTimer();
    }
  };

  useEffect(() => {
    if (visible !== open) {
      setVisibility(open);
    }
  }, [open]);

  useEffect(() => {
    if (visible) {
      startTimer();
      document.addEventListener('click', handleClickOutside);
      document.addEventListener('mouseover', handleMouseOver);
      document.addEventListener('mouseout', handleMouseOut);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('mouseover', handleMouseOver);
      document.removeEventListener('mouseout', handleMouseOut);
      timerIdRef?.current && clearTimeout(timerIdRef.current);
    };
  }, [visible]);

  const handleClose = () => {
    onClose();
    clearTimeout(timerIdRef.current);
    setVisibility(false);
  };

  const actualErrorMessagesNode =
    actualErrorMessages && !!actualErrorMessages.length ? (
      <span className={classes.alertActualMessage} data-testid="error-messages">
        <ul>
          {actualErrorMessages.map((actualErrorMessage, index) => (
            <li key={`error-${index}`}>{actualErrorMessage}</li>
          ))}
        </ul>
      </span>
    ) : null;

  return visible ? (
    <div ref={wrapperRef} className={classNames(classes.alertNotification, classes.open)} data-testid="alert-notification">
      <Banner dismiss dismissAltText="Close" onDismiss={handleClose} scope="content" stacked={isBannerMessageStacked} type={bannerType}>
        <BannerHeader>{message}</BannerHeader>
        <BannerNote>{bannerNote ? bannerNote : actualErrorMessagesNode}</BannerNote>
      </Banner>
    </div>
  ) : null;
};

Snackbar.propTypes = {
  message: PropTypes.node,
  bannerNote: PropTypes.string,
  bannerType: PropTypes.oneOf(bannerTypes),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  autoHideDuration: PropTypes.number,
};

export default Snackbar;
