import * as React from 'react';
import queryString from 'query-string';
import { UitkTertiaryButton as Button } from 'uitk-react-button';
import { Grid, Row, Col } from '@vapc-ui/grid';
import configList from '../../../config';
import {
  login,
  getConfig,
  setAppClientId,
  sendLog,
  getAppClientId,
  removeItemFromLocalStorage,
  usePageLoadLogger,
  useFeatureToggles,
  AUTH_IDENTITY_FLOWS,
  useAuth,
} from '@cpce/core-module';
import classNames from 'classnames';

import classes from './Login.module.scss';
import LoginImage from '../../assets/images/customer_care_illustration.svg';

function isOktaMode(currentAppClientId, oktaAppClientId) {
  return currentAppClientId === oktaAppClientId ? 'Okta' : 'Cognito';
}

export const AUTH_LOGIN_CALLED = 'isAuthLoginCalled';

removeItemFromLocalStorage(AUTH_LOGIN_CALLED);

const NON_EG_IDENTITY_FLOWS = [AUTH_IDENTITY_FLOWS.IAM_OKTA, AUTH_IDENTITY_FLOWS.IAM_COGNITO];

const Login = (props) => {
  const featureToggles = useFeatureToggles(React);
  const configData = getConfig(configList);
  const {
    IAM_DOMAIN_URL,
    IAM_V5_LOGIN_PATH,
    IAM_APP_CLIENT_ID,
    IAM_OKTA_APP_CLIENT_ID,
    CPCE_STATUS_PAGE_URL,
    EG_IDENTITY_DOMAIN_URL,
    EG_IDENTITY_CLIENT_ID,
    EG_IDENTITY_CODE_CHALLENGE_STEP_1,
    EG_IDENTITY_STATE_STEP_1,
  } = configData;
  const LOGIN_URL_OKTA = `${IAM_DOMAIN_URL}${IAM_V5_LOGIN_PATH}${IAM_OKTA_APP_CLIENT_ID}&redirectUrl=${window.location.origin}/login`;
  const EG_IDENTITY_LOGIN_URL = `${EG_IDENTITY_DOMAIN_URL}&client_id=${EG_IDENTITY_CLIENT_ID}&code_challenge=${EG_IDENTITY_CODE_CHALLENGE_STEP_1}&state=${EG_IDENTITY_STATE_STEP_1}&redirect_uri=${
    window.location.origin
  }/${featureToggles?.isEGIdentityPartnerSelectionEnabled ? 'eg-partner-selection' : 'dashboard'}`;
  const {
    userData,
    history,
    location: { search },
  } = props;

  const { isLoggedIn } = userData;
  const parsedSearch = queryString.parse(search);

  const { authFlow, setAuthFlowType } = useAuth(React);

  usePageLoadLogger(true);

  React.useEffect(() => {
    ((authCode) => {
      if (authCode && NON_EG_IDENTITY_FLOWS.includes(authFlow)) {
        const appClientId = getAppClientId(IAM_APP_CLIENT_ID);
        sendLog(`Mode: ${isOktaMode(appClientId, IAM_OKTA_APP_CLIENT_ID)} - AppClientId: ${appClientId} - Auth-code: ${authCode}`, true);
      }
    })(parsedSearch.code);
  }, [IAM_APP_CLIENT_ID, IAM_OKTA_APP_CLIENT_ID, authFlow, parsedSearch.code]);

  /**
   * This Effect will be called for Min 1 and Max 2 times.
   * 1. When feature toggle is still loading.
   * 2. When feature toggle is fully loaded.
   * authLogin() function will be called only once if the feature toggle is true.
   */
  React.useEffect(() => {
    if (parsedSearch.code && !isLoggedIn && NON_EG_IDENTITY_FLOWS.includes(authFlow)) {
      authLogin(parsedSearch.code);
    }
  }, []);

  if (isLoggedIn) {
    history.push('/');
    return null;
  }

  const oktaLogin = () => {
    sendLog(`Login to Okta using link on Console - ${LOGIN_URL_OKTA}`, true);
    setAppClientId(IAM_OKTA_APP_CLIENT_ID);
    setAuthFlowType(AUTH_IDENTITY_FLOWS.IAM_OKTA);
    window.location = LOGIN_URL_OKTA;
  };

  const egLogin = () => {
    sendLog(`Login to Eg Identity using link on Console - ${EG_IDENTITY_LOGIN_URL}`, true);
    setAppClientId(EG_IDENTITY_CLIENT_ID);
    setAuthFlowType(AUTH_IDENTITY_FLOWS.EG_IDENTITY);
    window.location = EG_IDENTITY_LOGIN_URL;
  };

  const handleAuthLoginCallFailed = () => {
    history.push('/');
  };

  const authLogin = async (authCode) => {
    const appClientId = getAppClientId(IAM_APP_CLIENT_ID);
    sendLog(
      `Calling Login back-end using ${isOktaMode(
        appClientId,
        IAM_OKTA_APP_CLIENT_ID
      )} - appClientId: ${appClientId} - auth code ${authCode}`,
      true
    );
    login(authCode, configData)
      .then(() => {
        history.push('/');
      })
      .catch((error) => {
        sendLog(`Failed on calling Login back-end ${error}`, true);
        handleAuthLoginCallFailed();
      });
  };

  if (parsedSearch?.appClientId === IAM_OKTA_APP_CLIENT_ID) {
    sendLog(`Login to Okta - ${LOGIN_URL_OKTA}`, true);
    setAppClientId(IAM_OKTA_APP_CLIENT_ID);
    window.location = LOGIN_URL_OKTA;
  }

  const cpceStatusPageOpen = () => {
    window.open(CPCE_STATUS_PAGE_URL);
  };

  return (
    <Grid className="loginForm" data-testid={'loginForm-testId'}>
      <Row className={classes.loginMargin}>
        <Col xs={3} xsOffset={2}>
          <h4 variant="h4" className={classes.loginHeader}>
            EG Travel Service Console
          </h4>
          <h6 className={classes.loginContent} variant="subtitle1">
            Set up an effortless customer
            <br />
            support experience
          </h6>
          <Button
            className={classNames('pl-n', 'mt-lg', classes.linkCSS)}
            data-testid="console-okta-sign-in"
            variant="link"
            onClick={oktaLogin}
          >
            Log in with SSO
          </Button>
          {featureToggles?.isEGIdentitySupportEnabled && (
            <Button
              className={classNames('pl-n', 'mt-lg', classes.linkCSS)}
              data-testid="console-eg-identity-sign-in"
              variant="link"
              onClick={egLogin}
            >
              Log in with EG Identity
            </Button>
          )}
          {CPCE_STATUS_PAGE_URL ? (
            <Button className={classNames('pl-n', 'mt-md', classes.linkCSS)} variant="link" onClick={cpceStatusPageOpen}>
              CP Site Operational Status
            </Button>
          ) : null}
        </Col>
        <Col xs={5} className={classes.loginSplash}>
          <img className={classes.imgLoginSplash} src={LoginImage} alt="Login" />
        </Col>
      </Row>
    </Grid>
  );
};

export default Login;
