import { STYLES_DATA } from '../stylesData';

const getHeight = (field, fieldHeightWithPadding, fieldHeight, fieldMaxLength) => {
  let height = 0;
  if (field) {
    height += fieldHeightWithPadding;
    const numberOfLines = field.length / fieldMaxLength;
    if (numberOfLines > 1) {
      height += fieldHeight * ~~numberOfLines;
    }
  }
  return height;
};

export const getCardsHeightArray = (cards) => {
  let cardsHeight = null;

  if (Array.isArray(cards)) {
    cardsHeight = Array(cards.length);

    cards.forEach(({ cardOverview, categorizedLinks, uncategorizedLinks }, index) => {
      let cardHeight = STYLES_DATA.IMAGE_HEIGHT + STYLES_DATA.PADDING * 2;

      cardHeight += getHeight(
        cardOverview,
        STYLES_DATA.LIST_ITEM_PRIMARY_HEIGHT,
        STYLES_DATA.CONTENT_DESCRIPTION_HEIGHT,
        STYLES_DATA.CONTENT_DESCRIPTION_TEXT_MAX_LENGTH
      );

      uncategorizedLinks.forEach(({ text, description }) => {
        if (text || description) {
          cardHeight += STYLES_DATA.PADDING * 2;
        }

        cardHeight += getHeight(
          text,
          STYLES_DATA.CONTENT_HEADER_HEIGHT,
          STYLES_DATA.CONTENT_HEADER_HEIGHT,
          STYLES_DATA.CONTENT_HEADER_TEXT_MAX_LENGTH
        );

        cardHeight += getHeight(
          description,
          STYLES_DATA.CONTENT_DESCRIPTION_HEIGHT + STYLES_DATA.CONTENT_DESCRIPTION_MARGIN_TOP,
          STYLES_DATA.CONTENT_DESCRIPTION_HEIGHT,
          STYLES_DATA.CONTENT_DESCRIPTION_TEXT_MAX_LENGTH
        );
      });

      categorizedLinks.forEach(({ categoryTitle, links }) => {
        cardHeight += getHeight(
          categoryTitle,
          STYLES_DATA.CONTENT_HEADER_HEIGHT + STYLES_DATA.PADDING * 2,
          STYLES_DATA.CONTENT_HEADER_HEIGHT,
          STYLES_DATA.CATEGORY_TITLE_MAX_LENGTH
        );

        links.forEach(({ text, description }) => {
          if (text || description) {
            cardHeight += STYLES_DATA.PADDING * 2;
          }
          cardHeight += getHeight(
            text,
            STYLES_DATA.CONTENT_HEADER_HEIGHT,
            STYLES_DATA.CONTENT_HEADER_HEIGHT,
            STYLES_DATA.CONTENT_HEADER_TEXT_MAX_LENGTH
          );

          cardHeight += getHeight(
            description,
            STYLES_DATA.CONTENT_DESCRIPTION_HEIGHT + STYLES_DATA.CONTENT_DESCRIPTION_MARGIN_TOP,
            STYLES_DATA.CONTENT_DESCRIPTION_HEIGHT,
            STYLES_DATA.CONTENT_DESCRIPTION_TEXT_MAX_LENGTH
          );
        });
      });

      cardsHeight[index] = cardHeight;
    });
  }

  return cardsHeight;
};
