// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dSalZpp7MPHPEq58CjLNNQ\\=\\={line-height:0}.dSalZpp7MPHPEq58CjLNNQ\\=\\= .fds-dropdown.fds-dropdown-open .fds-dropdown-content{max-width:none;max-height:none;margin-top:20px;bottom:auto !important}.dSalZpp7MPHPEq58CjLNNQ\\=\\= .fds-dropdown.fds-dropdown-open .fds-dropdown-content .fds-dropdown-inner-content{max-width:none;max-height:none;padding:0;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;color:#000}.dSalZpp7MPHPEq58CjLNNQ\\=\\= .fds-dropdown.fds-dropdown-open .fds-dropdown-content .fds-dropdown-inner-content a:link{color:#000}.dSalZpp7MPHPEq58CjLNNQ\\=\\= .fds-dropdown.fds-dropdown-open .fds-dropdown-content .fds-dropdown-inner-content a:visited,.dSalZpp7MPHPEq58CjLNNQ\\=\\= .fds-dropdown.fds-dropdown-open .fds-dropdown-content .fds-dropdown-inner-content a:hover,.dSalZpp7MPHPEq58CjLNNQ\\=\\= .fds-dropdown.fds-dropdown-open .fds-dropdown-content .fds-dropdown-inner-content a:active{color:#336dff}.dSalZpp7MPHPEq58CjLNNQ\\=\\= .fds-dropdown-text-trigger>.fds-button{margin:0;color:#fff !important;min-width:auto}.dSalZpp7MPHPEq58CjLNNQ\\=\\= .fds-dropdown-text-trigger>.fds-button .fds-button-label{font-weight:300 !important;white-space:nowrap}.dSalZpp7MPHPEq58CjLNNQ\\=\\= .fds-dropdown-text-trigger.fds-dropdown-open>.fds-button .fds-button-label{font-weight:500 !important}", ""]);
// Exports
exports.locals = {
	"global_navbar_dropdown": "dSalZpp7MPHPEq58CjLNNQ=="
};
module.exports = exports;
