import * as React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { useDeepCompareEffect } from 'react-use';
import { DataDogRUM } from './components/utility/datadog-rum';
import { BrandProvider } from '@shared-ui/brand-context';

import {
  egLogin,
  egLogout,
  getAppData,
  getConfig,
  getCurrentSubApp,
  getItemFromLocalStorage,
  loadUserPreferences,
  loadRolePreferences,
  useRolePreferences,
  registerSubApps,
  sendLog,
  setItemToLocalStorage,
  useFeatureToggles,
  useFeatureTogglesService,
  useLoadingScreen,
  useNotification,
  useSessionValidator,
  useSubAppLifeCycle,
  useToken,
  useTrustedPartners,
  useUser,
  getBrandConfiguration,
  iconData,
} from '@cpce/core-module';
import { IS_CONVERSATION_HUB_ENABLED } from '@cpce/console-shared-feature-toggles';

import configList from '../config';
import { CONVERSATION_HUB_URI, CONVERSATION_STUDIO_URI, disallowBookmarkingForPaths, LOGIN_URI, LOGOUT_URI, ROOT } from './constants';
import Home from './components/home/Home';
import GlobalNavBar from './components/nav-bar/global-navbar/GlobalNavBar';
import Login from './components/login/Login';
import Logout from './components/logout/Logout';
import { LoadingScreen } from '@isp/shared-components/src/components/loading-screen/LoadingScreen';
import Snackbar from './components/snackbar/Snackbar';
import { Modal } from '@vapc-ui/modal';
import NavigationPrompt from './components/navigation-prompt/NavigationPrompt';
import { ErrorModal } from './components/error-modal/ErrorModal';
import '@ferris/react/js/bundles/epc-ui-core-react';

import './Root.scss';
import EgPartnerSelection from './components/eg-partner-selection/EgPartnerSelection';

const registerImportMap = (importMap) => {
  const importMapElement = document.createElement('script');
  importMapElement.type = 'systemjs-importmap';
  importMapElement.textContent = JSON.stringify({
    imports: importMap,
  });
  document.head.appendChild(importMapElement);
};

const registerUnAuthenticatedImportMapped = async (isLoggedIn, history, featureToggles) => {
  const configData = getConfig(configList);
  const appData = await getAppData(configData);
  const isLocal = getItemFromLocalStorage('isLocal');

  window.__VAC_ENVIRONMENT__ = appData.flavor === 'dev' ? 'int' : appData.flavor;
  const subApps = appData.subAppConfigurations;
  const importMap = {};

  const { pathname } = history.location;
  const activeApp = getCurrentSubApp(pathname);
  const { BOOK_MARK_PATH_KEY } = configData;

  const unAuthenticatedAppRoutes = subApps?.reduce((acc, subApp) => {
    if (!subApp?.isUnauthenticatedApp) return acc;
    return [...acc, ...subApp.routes];
  }, []);

  if (activeApp?.routes?.some((route) => unAuthenticatedAppRoutes.includes(route))) {
    subApps.forEach((subApp) => {
      if (!subApp?.isUnauthenticatedApp) return;
      const version = isLocal ? 'dev' : subApp.enabled;
      importMap[subApp.name] = subApp.versions[version];
    });

    registerImportMap(importMap);
    registerSubApps(appData, configData, featureToggles);
  } else {
    // If current route is included in un-authenticated sub app routes
    // Then do not bookmark it and avoid re-route to login.
    doBookmarkAllowedPathsAndRedirectIfNotLoggedIn(isLoggedIn, BOOK_MARK_PATH_KEY, history);
  }
};

const registerImportMapped = async (featureToggles) => {
  const configData = getConfig(configList);
  const appData = await getAppData(configData);
  const isLocal = getItemFromLocalStorage('isLocal');

  window.__VAC_ENVIRONMENT__ = appData.flavor === 'dev' ? 'int' : appData.flavor;
  const subApps = appData.subAppConfigurations;
  const importMap = {};

  subApps?.forEach((subApp) => {
    const version = isLocal ? 'dev' : subApp.enabled;
    importMap[subApp.name] = subApp.versions[version];
  });

  registerImportMap(importMap);
  registerSubApps(appData, configData, featureToggles);
};

const clearFederatedSessionBeforeLogin = async (configData) => {
  sendLog('Clearing federated session before re-login', true);
  try {
    await egLogout(configData, true);
  } catch (e) {
    sendLog(`Failed to clear federated session due to ${e}`, true);
  }
};

const setupOpenWorldFederatedSession = async (authCode, configData, state, history, featureTogglesList, redirectPath) => {
  sendLog('Establishing open world federated session', true);
  try {
    await egLogin(authCode, configData, state, featureTogglesList);
    if (state !== 'eglogin1' && state !== 'eglogin2') {
      history.push(redirectPath);
    }
  } catch (e) {
    sendLog(`Failed to establish open world federated session due to ${e}`, true);
  }
};

function doBookmarkAllowedPathsAndRedirectIfNotLoggedIn(isLoggedIn, bookmarkPathKey, history) {
  const { pathname, search } = history.location;
  if (!disallowBookmarkingForPaths.includes(pathname) && !isUndefined(isLoggedIn) && !isLoggedIn) {
    setItemToLocalStorage(bookmarkPathKey, `${pathname}${search}`);
    history.push(LOGIN_URI);
  }
}

const Root = () => {
  const history = useHistory();
  const userData = useUser(React);
  const tokenData = useToken(React);
  const {
    activePartner: {
      profile: { partnerId, roles },
    },
    isAuthorized,
    hasEgIdentityAppAccess,
    isLoggedIn,
    isFederatedSession,
    isEGLoginSession,
    userId,
    userResourceUri,
    role,
  } = userData;
  const roleUri = roles?.[0]?.resourceUri;

  const isUnauthorized = isAuthorized === false;
  const hasEgIdentityAppAccessIssue = hasEgIdentityAppAccess === false;
  const isUnauthorizedModalErr = isFederatedSession ? partnerId && isUnauthorized : isUnauthorized;
  const noPartnerIdInFederatedSession = isFederatedSession && !partnerId;
  const showErrorModal = noPartnerIdInFederatedSession || isUnauthorizedModalErr || hasEgIdentityAppAccessIssue;
  const configData = getConfig(configList);
  const loadingScreenProps = useLoadingScreen(React);
  const snackbarProps = useNotification(React);
  useFeatureTogglesService(configData, userData, sendLog, React);
  const featureToggles = useFeatureToggles(React);
  const trustedPartners = useTrustedPartners(React);
  const [sessionState, sessionMessage] = useSessionValidator(
    React,
    configData,
    isLoggedIn,
    tokenData,
    disallowBookmarkingForPaths,
    featureToggles,
    sendLog,
    isFederatedSession,
    isEGLoginSession
  );
  const propsWithHistory = { history, location: history.location, userData, trustedPartners };
  const subApp = getCurrentSubApp();
  const subAppId = subApp?.id;
  const { BOOK_MARK_PATH_KEY, FEDERATED_SESSION_ROLE_PREFERENCES, FEDERATED_SESSION_AUTH_CODE } = configData;
  const {
    rolePreferences: { preferences: rolePreferences },
  } = useRolePreferences();

  const [isLoggingOutFederatedSession, setIsLoggingOutFederatedSession] = React.useState(false);

  const url = new URL(window.location.href);
  const authCode = url.searchParams.get('code');
  const state = url.searchParams.get('state');
  const canSetupFederatedSession = (url.pathname === '/dashboard' || url.pathname === '/eg-partner-selection') && authCode;
  const currFederatedSessionAuthCode = getItemFromLocalStorage(FEDERATED_SESSION_AUTH_CODE);
  const shouldClearFederatedSessionThenLogin =
    isLoggedIn &&
    canSetupFederatedSession &&
    (role || (isFederatedSession && currFederatedSessionAuthCode)) &&
    authCode !== currFederatedSessionAuthCode;

  useDeepCompareEffect(() => {
    if (shouldClearFederatedSessionThenLogin) {
      clearFederatedSessionBeforeLogin(configData);
    }
  }, [shouldClearFederatedSessionThenLogin, configData]);

  useDeepCompareEffect(() => {
    if (isLoggedIn && !shouldClearFederatedSessionThenLogin) {
      if (isFederatedSession) {
        subAppId && partnerId && userId && loadUserPreferences(undefined, subApp, partnerId, userId, true);
      } else {
        userResourceUri && subAppId && loadUserPreferences(userResourceUri, subApp);
      }
    }
  }, [subAppId, featureToggles, isLoggedIn, isFederatedSession, userResourceUri]);

  useDeepCompareEffect(() => {
    if (isLoggedIn && !shouldClearFederatedSessionThenLogin) {
      if (isFederatedSession) {
        loadRolePreferences(null, FEDERATED_SESSION_ROLE_PREFERENCES);
      } else {
        loadRolePreferences(roleUri);
      }
    }
  }, [featureToggles, isLoggedIn, isFederatedSession, roleUri, shouldClearFederatedSessionThenLogin]);

  React.useEffect(() => {
    (async () => {
      if (!Object.keys(featureToggles).length) {
        return;
      }

      if (canSetupFederatedSession || isFederatedSession || isLoggingOutFederatedSession) {
        if (isLoggedIn && !shouldClearFederatedSessionThenLogin) {
          await registerImportMapped(featureToggles);
        } else if (!isLoggedIn && canSetupFederatedSession) {
          const redirectPath = featureToggles[IS_CONVERSATION_HUB_ENABLED] ? CONVERSATION_HUB_URI : CONVERSATION_STUDIO_URI;
          await setupOpenWorldFederatedSession(authCode, configData, state, history, featureToggles, redirectPath);
        }
      } else {
        if (isLoggedIn) {
          await registerImportMapped(featureToggles);
        } else if (
          !isUndefined(isLoggedIn) &&
          !isLoggedIn &&
          history.location.pathname !== LOGIN_URI &&
          history.location.pathname !== LOGOUT_URI
        ) {
          await registerUnAuthenticatedImportMapped(isLoggedIn, history, featureToggles);
        } else {
          doBookmarkAllowedPathsAndRedirectIfNotLoggedIn(isLoggedIn, BOOK_MARK_PATH_KEY, history);
        }
      }
    })();
  }, [isLoggedIn, history, featureToggles, BOOK_MARK_PATH_KEY]);

  useSubAppLifeCycle(configData?.SUB_APPS_WITHOUT_NAV_BAR);

  const loadGlobalNav = () => {
    return subApp?.isUnauthenticatedApp || (rolePreferences?.hideNavigationBar && rolePreferences?.hideNavigationBar === 'true') ? null : (
      <GlobalNavBar {...propsWithHistory} configData={configData} />
    );
  };

  return (
    <BrandProvider config={getBrandConfiguration(iconData)}>
      <ErrorModal
        show={showErrorModal}
        isFederatedSession={isFederatedSession}
        isUnauthorizedErr={isUnauthorizedModalErr}
        hasEgIdentityAppAccessIssue={hasEgIdentityAppAccessIssue}
        configData={configData}
        isLoggedIn={isLoggedIn}
      />
      <NavigationPrompt />
      <Modal hideCloseButton {...sessionState}>
        {sessionMessage}
      </Modal>
      <LoadingScreen {...loadingScreenProps} />
      <Snackbar {...snackbarProps} />
      {<DataDogRUM />}

      {loadGlobalNav()}
      <Route exact path={ROOT} render={() => <Home {...propsWithHistory} />} />
      <Route exact path={LOGIN_URI} render={() => <Login {...propsWithHistory} />} />
      <Route
        exact
        path={'/eg-partner-selection'}
        render={featureToggles?.isEGIdentityPartnerSelectionEnabled ? () => <EgPartnerSelection /> : null}
      />
      <Route
        exact
        path={LOGOUT_URI}
        render={() => (
          <Logout
            isFederatedSession={userData?.isFederatedSession}
            isLoggingOutFederatedSession={isLoggingOutFederatedSession}
            toggleIsLoggingOutFederatedSession={setIsLoggingOutFederatedSession}
            isEGLoginSession={userData?.isEGLoginSession}
          />
        )}
      />
    </BrandProvider>
  );
};

export default Root;
