import { getCardsHeightArray } from '../index';

const getCurrIterationCounterAndNextIterationStartingIndex = (startIndex, array, maxHeight) => {
  let index,
    counter = 0,
    remaining = maxHeight;

  for (index = startIndex; index < array.length; index++) {
    let curr = array[index];
    if (counter === 0) {
      counter++;
      if (curr > remaining) {
        break;
      } else {
        remaining -= curr;
      }
    } else {
      if (curr > remaining) {
        index--;
        break;
      } else {
        remaining -= curr;
        counter++;
      }
    }
  }

  return { counter, index };
};

export const getTotalColumns = (cards, columnMaxHeight) => {
  const cardsHeightArr = getCardsHeightArray(cards);
  let totalColumns = 0;
  if (cardsHeightArr) {
    const { counter: firstCounter, index: secondIterationStartingIndex } = getCurrIterationCounterAndNextIterationStartingIndex(
      0,
      cardsHeightArr,
      columnMaxHeight
    );
    if (firstCounter !== 0) {
      totalColumns++;
    }

    const { counter: secondCounter, index: thirdIterationStartingIndex } = getCurrIterationCounterAndNextIterationStartingIndex(
      secondIterationStartingIndex + 1,
      cardsHeightArr,
      columnMaxHeight
    );

    if (secondCounter !== 0) {
      totalColumns++;
    }
    const { counter: thirdCounter } = getCurrIterationCounterAndNextIterationStartingIndex(
      thirdIterationStartingIndex + 1,
      cardsHeightArr,
      columnMaxHeight
    );
    if (thirdCounter !== 0) {
      totalColumns++;
    }
  }

  return totalColumns;
};
